import { defineStore } from "pinia";
import Category from "@/Interfaces/Category";
import { Action } from "@/Interfaces/Action";
import { GameStates } from "@/Interfaces/GameStates";
import Timer from "@/Interfaces/Timer";
import { computed, ComputedRef, ref, Ref } from "vue";
import axios from "axios";
import { useDiclaimer } from "@/Composables/useDisclaimer";
import i18next from "i18next";
import { z, ZodArray, ZodError } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "@/I18n/locales/fr/zod.json";
import { usePersonalAction } from "@/Composables/usePersonalAction";

i18next.init({
  lng: "fr",
  resources: {
    fr: { zod: translation },
  },
});

z.setErrorMap(zodI18nMap);

export const useGameStore = defineStore("game", () => {
  // Base for starting game
  const players: Ref<string[]> = ref([]);
  const inGame: Ref<GameStates> = ref(GameStates.NotStarted);
  const indexAction: Ref<number> = ref(0);
  const numberRounds: Ref<number> = ref(25);
  const minimumDrinks: Ref<number> = ref(2);
  const maximumDrinks: Ref<number> = ref(5);
  const selectedCategories: Ref<number[]> = ref([]);
  const localPlayersActions: Ref<Action[]> = ref([]);
  const pastActions: Ref<Action[]> = ref([]);

  const futuresActions: ComputedRef<Action[]> = computed(() => {
    const finalActions: Action[] = [];
    const selectedCategoriesValue = selectedCategories.value;
    // + 1 because of disclaimer
    const numberActionsToGet =
      numberRounds.value -
      pastActions.value.length -
      localPlayersActions.value.length +
      1;
    // get available actions (in selected categories and not in past actions)
    const availablesActions = allActions.value.filter((action) => {
      return (
        selectedCategoriesValue.includes(action.category.id) &&
        action.number_players <= players.value.length &&
        !pastActions.value.includes(action)
      );
    });
    const availablesLocalPlayersActions = localPlayersActions.value.filter(
      (action) => {
        return !pastActions.value.includes(action);
      }
    );
    shuffle(availablesActions);
    shuffle(availablesLocalPlayersActions);
    for (let i = 0; i < availablesLocalPlayersActions.length; i++) {
      finalActions.push(availablesLocalPlayersActions[i]);
    }
    for (let i = 0; i < numberActionsToGet; i++) {
      if (availablesActions.length > i) {
        finalActions.push(availablesActions[i]);
      }
    }
    shuffle(finalActions);
    if (pastActions.value.length === 0) {
      finalActions.unshift(useDiclaimer().disclaimer);
    }
    return finalActions;
  });

  const gameActions: ComputedRef<Action[]> = computed(() => {
    return [...pastActions.value, ...futuresActions.value];
  });

  // Game not started
  function updateSelectedCategories(newCategories: number[]) {
    selectedCategories.value = newCategories;
  }

  function addPlayer(name: string) {
    try {
      // Filtrer les erreurs liées aux joueurs et les retirer du tableau errors
      errors.value = errors.value.filter(
        (err) => !err.path.includes("players")
      );
      const simulatedPlayers = [...players.value, name];
      AddPlayersValidation.parse({
        players: simulatedPlayers,
      });
      players.value.push(name);
    } catch (error) {
      for (const err of (error as { errors: unknown[] }).errors) {
        errors.value.push(err);
      }
    }
  }

  function removePlayer(name: string) {
    try {
      const index = players.value.indexOf(name);
      // Filtrer les erreurs liées aux joueurs et les retirer du tableau errors
      errors.value = errors.value.filter(
        (err) => !err.path.includes("players")
      );
      if (inGame.value === GameStates.Started && players.value.length <= 2) {
        const simulatedPlayers = [...players.value];
        simulatedPlayers.splice(index, 1);
        RemovePlayersValidation.parse({
          players: simulatedPlayers,
        });
      }
      players.value.splice(index, 1);
    } catch (error) {
      for (const err of (error as { errors: unknown[] }).errors) {
        errors.value.push(err);
      }
    }
  }

  function updateMinimumAndMaximumDrinksWithVerification(
    min: number,
    max: number
  ) {
    try {
      errors.value = [];
      UpdateNumberDrinksValidation.parse({
        minimumDrinks: min,
        maximumDrinks: max,
      });
      minimumDrinks.value = min;
      maximumDrinks.value = max;
    } catch (error) {
      for (const err of (error as { errors: unknown[] }).errors) {
        errors.value.push(err);
      }
    }
  }

  function updateMinimumDrinks(min: number) {
    minimumDrinks.value = min;
  }

  function updateMaximumDrinks(max: number) {
    maximumDrinks.value = max;
  }

  function updateNumberRounds(rounds: number) {
    numberRounds.value = rounds;
  }

  // Validation
  const GameValidation = z.object({
    players: z
      .array(z.string().min(1).max(50))
      .min(2)
      .refine((players) => new Set(players).size === players.length, {
        message: "Les noms de joueurs doivent être uniques.",
      }),
    categories: z.array(z.number()).min(1),
    minimumDrinks: z.number().min(1).max(15),
    maximumDrinks: z.number().min(1).max(15),
    numberRounds: z.number().min(10).max(200),
  });

  const AddPlayersValidation = z.object({
    players: z
      .array(z.string().min(1).max(50))
      .refine((players) => new Set(players).size === players.length, {
        message: "Les noms de joueurs doivent être uniques.",
      }),
  });

  const UpdateNumberDrinksValidation = z
    .object({
      minimumDrinks: z.number().min(1).max(15),
      maximumDrinks: z.number().min(1).max(15),
    })
    .refine((data) => data.minimumDrinks < data.maximumDrinks, {
      message:
        "Le nombre de gorgées maximum doit être en dessous de celui minimum.",
      path: ["minimumAndMaximumDrinks"],
    });

  const RemovePlayersValidation = z.object({
    players: z.array(z.string().min(1).max(50)).min(2),
  });

  // Local data
  const randomPlayersUsed: Ref<string[]> = ref([]);
  const randomDrinksUsed: Ref<number[]> = computed(() => {
    const randomDrinks = [];
    for (let i = minimumDrinks.value; i <= maximumDrinks.value; i++) {
      randomDrinks.push(i);
    }
    shuffle(randomDrinks);
    return randomDrinks;
  });

  // Timers
  const timers: Ref<Timer[]> = ref([]);
  const timerAlert: Ref<number> = ref(0);

  // Form errors
  const errors: Ref<any[]> = ref([]);

  // Data from backend
  const allCategories: Ref<Category[]> = ref([]);
  const allActions: Ref<Action[]> = ref([]);

  function getAllCategoriesAndActions(): void {
    axios
      .post(
        "https://back.drinkolow.safiahalkoum.ch/gameApi",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        allActions.value = response.data.actions;
        allCategories.value = response.data.categories;
      })
      .catch((error) => {
        errors.value.push(
          "Erreur lors du chargement des données. Essayez de recharger la page."
        );
      });
  }

  // Game Started
  function lastAction(): void {
    if (indexAction.value != 0) {
      indexAction.value -= 1;
    }
  }

  function nextAction(): void {
    if (
      indexAction.value >= numberRounds.value ||
      gameActions.value.length - 1 === indexAction.value
    ) {
      stopGame();
    } else {
      shuffle(randomDrinksUsed.value);
      indexAction.value += 1;
      gameActions.value[indexAction.value].description = replaceTextVariable(
        gameActions.value[indexAction.value].description
      );
      pastActions.value.push(futuresActions.value[0]);

      gameActions.value[indexAction.value].description = replaceTextVariable(
        gameActions.value[indexAction.value].description
      );
      if (
        gameActions.value[indexAction.value].time &&
        gameActions.value[indexAction.value].timer_id != 0
      ) {
        const newTimer: Ref<Timer> = computed(() => {
          if (gameActions.value[indexAction.value]) {
            return {
              action_id: indexAction.value,
              time_full: gameActions.value[indexAction.value].time || 0,
              start_auto:
                gameActions.value[indexAction.value].start_auto || false,
              started: false,
              time_left: gameActions.value[indexAction.value].time || 0,
            };
          } else {
            return {
              action_id: indexAction.value,
              time_full: 0,
              start_auto: false,
              started: false,
              time_left: 0,
            };
          }
        });
        createTimer(newTimer.value);
      }
    }
  }

  function startGame() {
    try {
      errors.value = [];
      GameValidation.parse({
        players: players.value,
        categories: selectedCategories.value,
        minimumDrinks: minimumDrinks.value,
        maximumDrinks: maximumDrinks.value,
        numberRounds: numberRounds.value,
      });
      if (errors.value.length === 0) {
        inGame.value = GameStates.Started;
        pastActions.value.push(futuresActions.value[0]);
      }
    } catch (error) {
      errors.value = (error as { errors: any }).errors;
    }
  }

  function stopGame(): void {
    const timerLengthBase = timers.value.length;
    for (let i = 0; i < timerLengthBase; i++) {
      stopTimer(0);
    }
    inGame.value = GameStates.Finished;
    pastActions.value = [];
  }

  function restartGame(): void {
    inGame.value = GameStates.NotStarted;
    indexAction.value = 0;
  }

  function stopAndRestartGame(): void {
    stopGame();
    restartGame();
  }

  function replaceTextVariable(text: string) {
    const indexPlayers: Ref<number> = ref(-1);
    const indexDrinks: Ref<number> = ref(-1);
    randomPlayersUsed.value = [...players.value];
    shuffle(randomPlayersUsed.value);
    shuffle(randomDrinksUsed.value);
    return text
      .replace(/RANDOM_DRINKS/g, () => {
        indexDrinks.value++;
        return randomDrinksUsed.value[indexDrinks.value].toString();
      })
      .replace(/RANDOM_PLAYER/g, () => {
        indexPlayers.value++;
        return randomPlayersUsed.value[indexPlayers.value];
      });
  }

  function shuffle(data: any[]): void {
    for (let i = data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [data[i], data[j]] = [data[j], data[i]]; // Échange d'éléments
    }
  }

  // Timers

  function createTimer(timer: Timer) {
    timers.value.push(timer);
    gameActions.value[indexAction.value].timer_id = timers.value.length - 1;
    if (timers.value[timers.value.length - 1].start_auto) {
      startTimer(timers.value.length - 1);
    }
  }

  function startTimer(id: number): void {
    const timer = timers.value[id];

    if (!timer.started) {
      timer.started = true;

      // Utilisez setInterval pour décrémenter time_left chaque seconde
      timer.interval_id = setInterval(() => {
        if (timer.time_left > 0) {
          timer.time_left--;
        } else {
          if (timer.interval_id) {
            clearInterval(timer.interval_id); // Arrêtez l'intervalle lorsque le temps est écoulé
            timerAlert.value = timer.action_id;
          }
          timer.started = false;
        }
      }, 1000); // Interval d'une seconde (en millisecondes)
    }
  }

  function pauseTimer(id: number): void {
    const timer = timers.value[id];

    if (timer.started && timer.interval_id) {
      clearInterval(timer.interval_id); // Arrêtez l'intervalle si le minuteur est en cours et que l'intervalle existe
      timer.started = false;
    }
  }

  function restartTimer(id: number) {
    const timer = timers.value[id];

    if (timer.started && timer.interval_id) {
      clearInterval(timer.interval_id); // Arrêtez l'intervalle précédent
    }
    timer.started = false;
    timer.time_left = timer.time_full; // Réinitialisez le temps restant au temps initial

    startTimer(id); // Démarrez à nouveau le timer en utilisant la fonction startTimer
  }

  function stopTimer(id: number): void {
    const timer = timers.value[id];

    if (timer.started && timer.interval_id) {
      clearInterval(timer.interval_id); // Arrêtez l'intervalle s'il est en cours
    }

    // Réinitialisez le timer
    timer.started = false;
    timer.time_left = timer.time_full;
    delete gameActions.value[indexAction.value].timer_id; // Supprimez la référence au timer

    // Supprimez le timer du tableau des timers
    timers.value.splice(id, 1);
  }

  function secondsToMinutesAndSeconds(totalSeconds: number): string {
    // https://askjavascript.com/how-to-convert-seconds-to-minutes-and-seconds-in-javascript/
    const minutes: number = Math.floor(totalSeconds / 60);
    const seconds: number = totalSeconds % 60;
    const secondsString = ref(seconds.toString());
    if (seconds < 10) {
      secondsString.value = "0" + seconds;
    }
    if (minutes < 1) {
      return "00:" + secondsString.value;
    }
    return minutes + ":" + secondsString.value;
  }

  function addCustomAction(action: string) {
    try {
      // Filtrer les erreurs liées aux actions personnalisées et les retirer du tableau errors
      errors.value = errors.value.filter(
        (err) => !err.path.includes("actionCustom")
      );
      const actionValidation = z.object({
        actionCustom: z.string().min(20).max(300),
      });
      actionValidation.parse({ actionCustom: action });
      const { personalAction } = usePersonalAction();
      personalAction.description = action;
      localPlayersActions.value.push(personalAction);
    } catch (error) {
      for (const err of (error as { errors: unknown[] }).errors) {
        errors.value.push(err);
      }
    }
  }

  function deleteCustomAction(index: number) {
    localPlayersActions.value.splice(index, 1);
  }

  function deleteAllCustomActions() {
    localPlayersActions.value = [];
  }

  function addSuggestionAction(action: string, category_id: number) {
    try {
      // Filtrer les erreurs liées aux actions personnalisées et les retirer du tableau errors
      errors.value = errors.value.filter(
        (err) => !err.path.includes("actionSuggestion")
      );
      const actionValidation = z.object({
        actionSuggestion: z.string().min(20).max(300),
      });
      actionValidation.parse({ actionSuggestion: action });
      // send to backend
      axios.post(
        "https://back.drinkolow.safiahalkoum.ch/suggestionApi",
        { action, category_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      for (const err of (error as { errors: unknown[] }).errors) {
        errors.value.push(err);
      }
    }
  }

  return {
    players,
    inGame,
    indexAction,
    numberRounds,
    minimumDrinks,
    maximumDrinks,
    selectedCategories,
    localPlayersActions,
    gameActions,
    timers,
    timerAlert,
    errors,
    updateSelectedCategories,
    addPlayer,
    removePlayer,
    updateMinimumAndMaximumDrinksWithVerification,
    updateMinimumDrinks,
    updateMaximumDrinks,
    updateNumberRounds,
    getAllCategoriesAndActions,
    allCategories,
    allActions,
    lastAction,
    nextAction,
    startGame,
    stopGame,
    restartGame,
    stopAndRestartGame,
    startTimer,
    pauseTimer,
    stopTimer,
    restartTimer,
    secondsToMinutesAndSeconds,
    futuresActions,
    addCustomAction,
    deleteCustomAction,
    deleteAllCustomActions,
    addSuggestionAction,
  };
});
