<script setup lang="ts">
import { computed, defineProps, ref } from "vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import EditCategories from "@/components/Form/EditCategories.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import MediumText from "@/components/Texts/MediumText.vue";
import SmallText from "@/components/Texts/SmallText.vue";
import ExtraSmallText from "@/components/Texts/ExtraSmallText.vue";
import InputTextarea from "@/components/Form/Common/InputTextarea.vue";
import PrimaryButton from "@/components/Form/Common/PrimaryButton.vue";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";
import SecondaryButton from "@/components/Form/Common/SecondaryButton.vue";
import ErrorText from "@/components/Texts/ErrorText.vue";
import SuccessText from "@/components/Texts/SuccessText.vue";
import InputSelectOption from "@/components/Form/Common/InputSelectOption.vue";

const props = defineProps<{
  dialogId: number;
}>();

const gameStore = useGameStore();

const textValue = ref("");
const categoryId = ref(1);

const successAction = ref(false);

const onSubmit = () => {
  successAction.value = false;
  gameStore.addSuggestionAction(textValue.value, categoryId.value);
  if (!errorAction.value) {
    textValue.value = "";
    successAction.value = true;
  }
};

const errorAction = computed(() => {
  return gameStore.errors.find((error) =>
    error.path.includes("actionSuggestion")
  );
});

const categories = computed(() => {
  return gameStore.allCategories;
});

const categoriesOptions = computed(() => {
  return categories.value.map((category) => ({
    label: category.name,
    value: category.id,
  }));
});
</script>
<template>
  <BottomSheet
    :out-side-closable="true"
    :all-draggable="false"
    :dialog-id="props.dialogId"
  >
    <div class="flex flex-col space-y-4">
      <PrimaryLabel
        text="Proposer une suggestion d'action"
        class="mb-2 text-left"
      />
      <ExtraSmallText
        class="text-custom-lightGray text-left"
        text="Personne ne pourra voir la suggestion hormis les admin tant que
        celle-ci ne sera pas validée par ces derniers. Entrer une idée d'action
        que nous étudierons et modifierons potentiellement afin de l'intégrer
        au jeu et en faire profiter tout le monde ! Merci beaucoup !"
      />
      <PrimaryLabel text="Action :" class="text-left" />
      <InputTextarea
        name="action"
        placeholder="Julie fais 3 pompes ou boit 4 gorgées."
        id="action"
        :is_required="true"
        v-model="textValue"
      />
      <ErrorText
        v-if="errorAction"
        :text="errorAction.message"
        class="mt-2 text-left"
      />
      <PrimaryLabel text="Categorie :" class="text-left" />
      <InputSelectOption v-model="categoryId" :options="categoriesOptions" />
      <SuccessText
        v-if="successAction"
        text="La proposition a bien été envoyée !"
      />
      <PrimaryButton @click="onSubmit" label="Proposer" />
    </div>
  </BottomSheet>
</template>
