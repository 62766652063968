<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  title: string;
}>();
</script>

<template>
  <h2 class="font-semibold text-white md:text-3xl text-2xl">
    {{ props.title }}
  </h2>
</template>
