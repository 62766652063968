<script setup lang="ts">
import { defineEmits, defineProps, Ref, ref } from "vue";
import { useField } from "vee-validate";
import ErrorText from "@/components/Texts/ErrorText.vue";

const props = defineProps<{
  name: string;
  placeholder: string;
  id: string;
  is_required: boolean;
  modelValue: string;
}>();

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});
const emit = defineEmits(["update:modelValue"]);

const updateValue = ($event: Event) => {
  emit("update:modelValue", ($event.target as HTMLInputElement).value);
  handleChange($event);
};
</script>

<template>
  <div class="flex flex-col space-y-2 w-full">
    <input
      type="text"
      class="w-full placeholder-gray-400 focus:backdrop-blur-sm focus:border-purple-700 bg-opacity-10 bg-transparent border border-white rounded-lg px-4 py-2 text-[#e2e1e5] transition duration-200 shadow-md"
      :name="props.name"
      :placeholder="props.placeholder"
      :id="props.id"
      :required="props.is_required"
      :value="modelValue"
      @input="updateValue($event)"
      @blur="handleBlur"
    />
    <ErrorText class="text-left" v-if="errorMessage" :text="errorMessage" />
  </div>
</template>
