<script setup lang="ts">
import { defineProps, defineEmits, computed, watch, ComputedRef } from "vue";
import { useGameStore } from "@/Store/useGameStore";
const gameStore = useGameStore();

const props = defineProps<{
  value: number;
  name: string;
  is_required: boolean;
  modelValue: number[];
  color: string;
  color2: string;
}>();

const valueString = computed(() => {
  return props.value.toString();
});

function updateCategories(event: Event) {
  const selectedCategoryId = props.value;
  const selectedCategoriesNew = [...props.modelValue];

  if (event.target instanceof HTMLInputElement) {
    const index = selectedCategoriesNew.indexOf(selectedCategoryId);
    if (event.target.checked && index === -1) {
      selectedCategoriesNew.push(selectedCategoryId);
    } else {
      if (index !== -1 && selectedCategoriesNew.length > 1) {
        selectedCategoriesNew.splice(index, 1);
      }
    }
    gameStore.updateSelectedCategories(selectedCategoriesNew);
  }
}

const gradientStyle = computed(() => {
  return `linear-gradient(to right, ${props.color}, ${props.color2}) 1`;
});
</script>

<template>
  <div class="relative">
    <div
      class="border border-2 box-border text-md px-2 py-1 rounded-lg text-white"
      :style="{
        borderImage: gradientStyle,
        '--gradient-color1': props.color,
        '--gradient-color2': props.color2,
      }"
      :class="[{ 'background-custom': props.modelValue.includes(props.value) }]"
    >
      <input
        type="checkbox"
        :name="props.name"
        :value="props.value"
        :id="valueString"
        @change="updateCategories"
        :checked="props.modelValue.includes(props.value)"
      />
      <label :for="valueString" class="cursor-pointer">{{ props.name }}</label>
    </div>
  </div>
</template>

<style scoped lang="scss">
input[type="checkbox"] {
  display: none; /* Masquer la case à cocher par défaut */
}

.background-custom {
  background: linear-gradient(
    to right,
    var(--gradient-color1),
    var(--gradient-color2)
  );
  color: black;
}
</style>
