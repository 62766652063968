<script setup lang="ts">
/* Inspiré de : https://play.vuejs.org/#eNp9UstOwzAQ/BXjCyDRBKniUtKKh3qAAyDo0ZfgblO3jm3Zm7RV1X9nHUelSKineGdnxrMb7/mjc1nbAB/xIkivHLIA2LiJMKp21iPbMw8LdmALb2t2SdRLYYSR1gSiLu2GjSPhCn0D18IUeXIhPRUItdMlAlWMFd8NojXsQWol12PBe/VF/Ao+mdmq0lDkiZYkM1+aoFD1NSGOtQO16NWkWoLWtshjYOrmfwRFfhKAyoA7HY9ZOwCD4AelRNXCTYdoKFvoEbaPbnj0GjHrSqlwx26zu8CgDHAvzCGaHr3ifk6d0CaXXjlit0lCG+pi8BuOgda4UFW2CtbQH+j4gktbO6XBv7t4dxB8lJxir6RpN68dFjdOFyZcLkGu/8FXYRsxwT88BPAtCH7sYekrwNSefr3Bls7HZm3njSb2meYnBKubmDHRnhozp9gnvC7tS/eOlKlmYbpFoJX2Q8WgkXno+ILT23o+M/pv3GE27HS0T374AbBs70k= */

import { defineProps, ref } from "vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import ModalText from "@/components/Texts/ModalText.vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";

const props = defineProps<{
  dialogId: number;
}>();

const sections = ref([false, false, false, false]);

const toggleSection = (index: number) => {
  for (let i = 0; i < sections.value.length; i++) {
    if (i === index) {
      sections.value[i] = !sections.value[i];
    } else {
      sections.value[i] = false;
    }
  }
};
</script>

<template>
  <!-- Modal Aide -->
  <BottomSheet
    :dialog-id="props.dialogId"
    :all-draggable="false"
    :out-side-closable="true"
  >
    <div class="flex flex-col space-y-4 h-full overflow-y-auto">
      <PrimaryLabel text="Comment jouer ?" class="mb-2" />
      <ModalText class="px-8 text-left">
        <ol class="list-decimal">
          <li @click="toggleSection(0)" @touch="toggleSection(0)">
            <b class="text-gray-200">Création de la partie</b>
            <i v-if="sections[0]" class="bi bi-caret-up"></i>
            <i v-else class="bi bi-caret-down"></i>
            <ul v-if="sections[0]" class="ml-5 list-disc">
              <li>
                Ajoutez les joueurs
                <p>
                  - Chaque joueur sera tiré au hasard durant la partie afin de
                  réaliser des actions.
                </p>
              </li>
              <li>Ajoutez les catégories</li>
              <li>
                Choisissez la plage de gorgées
                <p>
                  - Lors de chaque action les gorgées seront tiré au hasard dans
                  la plage de gorgées choisis.
                </p>
              </li>
              <li>Choisissez le nombre de tours</li>
            </ul>
            <br />
          </li>
          <li @click="toggleSection(1)" @touch="toggleSection(1)">
            <b class="text-gray-200">Jouer</b>
            <i v-if="sections[1]" class="bi bi-caret-up"></i>
            <i v-else class="bi bi-caret-down"></i>
            <ul v-if="sections[1]" class="ml-5 list-disc">
              <li>
                En appuyant sur la partie droite de l'écran, vous allez à la
                prochaine action.
              </li>
              <li>
                En appuyant sur la partie gauche de l'écran, vous allez à la
                dernière question.
              </li>
              <li>
                Le menu paramètres en bas à droite vous permet de visualiser les
                différents timers ainsi que de modifier les joueurs.
              </li>
            </ul>
            <br />
          </li>
          <li @click="toggleSection(2)" @touch="toggleSection(2)">
            <b class="text-gray-200">Recommencer</b>
            <i v-if="sections[2]" class="bi bi-caret-up"></i>
            <i v-else class="bi bi-caret-down"></i>
            <ul v-if="sections[2]" class="ml-5 list-disc">
              <li>
                Appuyez sur l'écran afin de retourner sur l'écran de création de
                partie.
              </li>
              <li>Voir "Création de la partie"</li>
            </ul>
            <br />
          </li>
          <li @click="toggleSection(3)" @touch="toggleSection(3)">
            <b class="text-gray-200">Catégories</b>
            <i v-if="sections[3]" class="bi bi-caret-up"></i>
            <i v-else class="bi bi-caret-down"></i>
            <ul v-if="sections[3]" class="ml-5 list-disc">
              <li>
                <b>Actions :</b> Des défis et actions amusantes à relever avec
                des conséquences.
              </li>
              <li>
                <b>Boire pour boire :</b> des règles simples, buvez à chaque
                tour.
              </li>
              <li>
                <b>Re-découverte :</b> permet aux joueurs de découvrir davantage
                les autres, révélant des anecdotes inédites.
              </li>
              <li>
                <b>Sans Gêne :</b> des défis qui vous poussent hors de votre
                zone de confort.
              </li>
              <li>
                <b>Olé Olé :</b> des défis coquins pour une soirée plus
                pimentée.
              </li>
              <li>
                <b>Marijuana :</b> des défis inspirés par la culture du cannabis
                pour une touche de décontraction.
              </li>
              <li>
                <b>Basique :</b> des règles classiques pour compléter une partie
                sans besoin de complications.
              </li>
            </ul>
            <br />
          </li>
        </ol>
      </ModalText>
    </div>
  </BottomSheet>
</template>
