<script setup lang="ts">
import { computed, ComputedRef, Ref } from "vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import ModalText from "@/components/Texts/ModalText.vue";
import { useDialogsStore } from "@/Store/useDialogsStore";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";

const gameStore = useGameStore();

const gameActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.gameActions;
});

const indexAction: ComputedRef<number> = computed(() => {
  return gameStore.indexAction;
});

const dialogsStore = useDialogsStore();
const helperModal = dialogsStore.createNewDialog();

const isOpen: Ref<boolean> = computed(() => {
  return dialogsStore.dialogs[helperModal];
});

const toogleModal = () => {
  if (isOpen.value) {
    dialogsStore.closeAllModals();
  } else {
    dialogsStore.openModal(helperModal);
  }
};
</script>

<template>
  <div
    class="fixed top-4 right-4 z-40 w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
    @click="toogleModal"
  >
    <span class="text-white text-3xl"
      ><i class="bi bi-question text-white"></i
    ></span>
  </div>
  <!-- Modal Aide -->
  <BottomSheet
    :dialog-id="helperModal"
    :all-draggable="true"
    :out-side-closable="true"
  >
    <div class="flex flex-col space-y-4">
      <PrimaryLabel text="Aide" class="mb-2" />
      <ModalText class="text-left">
        {{ gameActions[indexAction].help }}
      </ModalText>
    </div>
  </BottomSheet>
</template>
