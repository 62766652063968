<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <p
    class="text-md md:text-lg w-full tracking-wide z-50 break-words text-white"
  >
    {{ props.text }}
  </p>
</template>
