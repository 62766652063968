<script setup lang="ts">
import { computed, Ref, ref, watch } from "vue";
import BigTitle from "@/components/Title/BigTitle.vue";
import ErrorText from "@/components/Texts/ErrorText.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import InputNumber from "@/components/Form/Common/InputNumber.vue";
import PrimaryButton from "@/components/Form/Common/PrimaryButton.vue";
import { useGameStore } from "@/Store/useGameStore";
import EditPlayers from "@/components/Form/EditPlayers.vue";
import EditNumberDrinks from "@/components/Form/EditNumberDrinks.vue";
import { Form } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import * as zod from "zod";
import { z } from "zod";
import EditCategories from "@/components/Form/EditCategories.vue";

// Get all necessary reactive stuff from gameStore
const gameStore = useGameStore();

const numberRounds: Ref<number> = ref(gameStore.numberRounds);

watch(
  () => numberRounds.value,
  () => {
    gameStore.updateNumberRounds(numberRounds.value);
  },
  { deep: true }
);

const validationSchema = toTypedSchema(
  zod
    .object({
      minimumDrinks: z.number().min(1).max(15),
      maximumDrinks: zod.number().min(1).max(15),
      numberRounds: z.number().min(10).max(200),
    })
    .refine((data) => data.minimumDrinks < data.maximumDrinks, {
      message:
        "Le nombre de gorgées maximum doit être en dessous de celui minimum.",
      path: ["maximumDrinks"],
    })
  /*.refine((data) => gameStore.players.length >= 2, {
      message: "Le nombre de joueurs doit être au minimum de 2.",
      path: ["player-name"],
    })
    .refine((data) => gameStore.selectedCategories.length >= 2, {
      message: "Le nombre de joueurs doit être au minimum de 2.",
      path: ["categories"],
    })*/
);

const errorPlayers = computed(() => {
  return gameStore.errors.find((error) => error.path.includes("players"));
});
</script>

<template>
  <Form :validation-schema="validationSchema" @submit="gameStore.startGame()">
    <BigTitle title="Création de la partie" class="text-center" />
    <EditPlayers />
    <ErrorText
      v-if="errorPlayers"
      :text="errorPlayers.message"
      class="mt-2 text-left"
    />
    <EditCategories />
    <EditNumberDrinks />
    <div class="flex flex-col space-y-2">
      <PrimaryLabel text="Nombre de tours" class="mb-2 text-left" />
      <InputNumber
        id="numberRounds"
        name="numberRounds"
        placeholder="25"
        :is_required="true"
        v-model.number="numberRounds"
      />
    </div>
    <PrimaryButton label="Commencer la partie" />
  </Form>
</template>
