import { Action } from "@/Interfaces/Action";
import Category from "@/Interfaces/Category";
import { User } from "@/Interfaces/User";

export function useDiclaimer() {
  const fictiveUser: User = {
    id: -1,
    name: "",
    email: "",
    email_verified_at: "",
  };
  const categoryForDisclaimer: Category = {
    id: -1,
    name: "",
    user: fictiveUser,
    color: "#4338ca",
    color2: "#9333ea",
  };
  const disclaimer: Action = {
    id: 0,
    description:
      "L'abus d'alcool est dangereux pour la santé. " +
      "En poursuivant vous confirmez être responsable des éventuelles conséquences que pourrait " +
      "engendrer l'utilisation de DrinkoLow.",
    category: categoryForDisclaimer,
    number_players: 1,
  };

  return {
    disclaimer,
  };
}
