<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  label: string;
}>();
</script>

<template>
  <button
    class="text-white md:text-lg text-md px-2 py-1 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-700 cursor-pointer"
  >
    {{ props.label }}
  </button>
</template>
