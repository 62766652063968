import { Action } from "@/Interfaces/Action";
import Category from "@/Interfaces/Category";
import { User } from "@/Interfaces/User";

export function usePersonalAction() {
  const fictiveUser: User = {
    id: -1,
    name: "",
    email: "",
    email_verified_at: "",
  };
  const categoryForPersonalAction: Category = {
    id: -1,
    name: "Action personnalisée",
    user: fictiveUser,
    color: "#4338ca",
    color2: "#9333ea",
  };
  const personalAction: Action = {
    id: 0,
    description: "",
    category: categoryForPersonalAction,
    number_players: 1,
  };

  return {
    personalAction,
  };
}
