<script setup lang="ts">
/* Inspiré de : https://play.vuejs.org/#eNp9UstOwzAQ/BXjCyDRBKniUtKKh3qAAyDo0ZfgblO3jm3Zm7RV1X9nHUelSKineGdnxrMb7/mjc1nbAB/xIkivHLIA2LiJMKp21iPbMw8LdmALb2t2SdRLYYSR1gSiLu2GjSPhCn0D18IUeXIhPRUItdMlAlWMFd8NojXsQWol12PBe/VF/Ao+mdmq0lDkiZYkM1+aoFD1NSGOtQO16NWkWoLWtshjYOrmfwRFfhKAyoA7HY9ZOwCD4AelRNXCTYdoKFvoEbaPbnj0GjHrSqlwx26zu8CgDHAvzCGaHr3ifk6d0CaXXjlit0lCG+pi8BuOgda4UFW2CtbQH+j4gktbO6XBv7t4dxB8lJxir6RpN68dFjdOFyZcLkGu/8FXYRsxwT88BPAtCH7sYekrwNSefr3Bls7HZm3njSb2meYnBKubmDHRnhozp9gnvC7tS/eOlKlmYbpFoJX2Q8WgkXno+ILT23o+M/pv3GE27HS0T374AbBs70k= */

import { computed, reactive, ref, Ref } from "vue";
import HelperModal from "@/components/Menu/HelperModal.vue";
import { useDialogsStore } from "@/Store/useDialogsStore";
import { useGameStore } from "@/Store/useGameStore";
import { GameStates } from "@/Interfaces/GameStates";
import { ComputedRef } from "vue/dist/vue";
import ModalStopGame from "@/components/Modals/ModalStopGame.vue";
import ModalPlayers from "@/components/Modals/ModalPlayers.vue";
import ModalNumberDrinks from "@/components/Modals/ModalNumberDrinks.vue";
import ModalCategories from "@/components/Modals/ModalCategories.vue";
import ModalCustomActions from "@/components/Modals/ModalCustomActions.vue";
import ModalSuggestionActions from "@/components/Modals/ModalSuggestionActions.vue";

const isOpen: Ref<boolean> = ref(false);

const gameStore = useGameStore();
const dialogsStore = useDialogsStore();
const helper = dialogsStore.createNewDialog();
const playersDialog = dialogsStore.createNewDialog();
const stopGameDialog = dialogsStore.createNewDialog();
const numberDrinksDialog = dialogsStore.createNewDialog();
const categoriesDialog = dialogsStore.createNewDialog();
const actionsCustomDialog = dialogsStore.createNewDialog();
const actionsSuggestionsDialog = dialogsStore.createNewDialog();

const inGame: ComputedRef<number> = computed(() => {
  return gameStore.inGame;
});

const subMenus: { icon: string; action: () => void; inGameOnly: boolean }[] =
  reactive([
    {
      icon: "bi-box-arrow-left",
      action: () => {
        dialogsStore.openModal(stopGameDialog);
        isOpen.value = false;
      },
      inGameOnly: true,
    },
    {
      icon: "bi-person-gear",
      action: () => {
        dialogsStore.openModal(playersDialog);
        isOpen.value = false;
      },
      inGameOnly: true,
    },
    {
      icon: "bi-cup-straw",
      action: () => {
        dialogsStore.openModal(numberDrinksDialog);
        isOpen.value = false;
      },
      inGameOnly: true,
    },
    {
      icon: "bi-card-checklist",
      action: () => {
        dialogsStore.openModal(categoriesDialog);
        isOpen.value = false;
      },
      inGameOnly: true,
    },
    {
      icon: "bi-plus-circle",
      action: () => {
        dialogsStore.openModal(actionsCustomDialog);
        isOpen.value = false;
      },
      inGameOnly: false,
    },
    {
      icon: "bi-plus-circle-dotted",
      action: () => {
        dialogsStore.openModal(actionsSuggestionsDialog);
        isOpen.value = false;
      },
      inGameOnly: false,
    },
    {
      icon: "bi-info-lg",
      action: () => {
        dialogsStore.openModal(helper);
        isOpen.value = false;
      },
      inGameOnly: false,
    },
  ]);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="mx-auto">
    <!-- Cercle principal fixé -->
    <div
      class="w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
      @click="toggleMenu"
      :class="{ 'rotate-45': isOpen, 'rotate-0': !isOpen }"
    >
      <span class="text-white text-3xl"><i class="bi bi-gear"></i></span>
    </div>

    <Transition>
      <div v-if="inGame === GameStates.Started">
        <div
          v-if="isOpen"
          key="submenu"
          class="absolute bottom-16 right-6 md:right-9"
        >
          <div v-for="(subMenu, index) in subMenus" :key="index">
            <div
              @click="subMenu.action"
              class="w-12 h-12 rounded-full flex justify-center items-center cursor-pointer mb-4 bg-gradient-to-b from-gray-900 to-gray-800"
            >
              <span class="text-white text-xl">
                <i class="bi" :class="subMenu.icon"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="isOpen"
          key="submenu"
          class="absolute bottom-[5.5rem] right-2"
        >
          <div v-for="(subMenu, index) in subMenus" :key="index">
            <div
              v-if="!subMenu.inGameOnly"
              @click="subMenu.action"
              class="w-12 h-12 rounded-full flex justify-center items-center cursor-pointer mb-4 bg-gradient-to-b from-gray-900 to-gray-800"
            >
              <span class="text-white text-xl">
                <i class="bi" :class="subMenu.icon"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <!-- Modal Stop Game -->
  <ModalStopGame :dialog-id="stopGameDialog" />
  <!-- Modal Number Drinks -->
  <ModalNumberDrinks :dialog-id="numberDrinksDialog" />
  <!-- Modal Joueurs -->
  <ModalPlayers :dialog-id="playersDialog" />
  <!-- Modal Categories -->
  <ModalCategories :dialog-id="categoriesDialog" />
  <!-- Modal Actions Custom -->
  <ModalCustomActions :dialog-id="actionsCustomDialog" />
  <!-- Modal Actions Suggestion -->
  <ModalSuggestionActions :dialog-id="actionsSuggestionsDialog" />
  <!-- Modal Infos Game -->
  <HelperModal :dialog-id="helper" />
</template>

<style scoped lang="scss">
.rotate-45 {
  transform: rotate(180deg);
}

.rotate-0 {
  transform: rotate(0deg);
}

.v-leave-active,
.v-enter-active {
  transition: opacity 1s, transform 1s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
