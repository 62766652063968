<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <h1 class="text-white md:text-2xl text-xl">{{ props.text }}</h1>
</template>
