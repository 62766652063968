<script setup lang="ts">
import { computed, defineProps, ref } from "vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import EditCategories from "@/components/Form/EditCategories.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import MediumText from "@/components/Texts/MediumText.vue";
import SmallText from "@/components/Texts/SmallText.vue";
import ExtraSmallText from "@/components/Texts/ExtraSmallText.vue";
import InputTextarea from "@/components/Form/Common/InputTextarea.vue";
import PrimaryButton from "@/components/Form/Common/PrimaryButton.vue";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";
import SecondaryButton from "@/components/Form/Common/SecondaryButton.vue";
import ErrorText from "@/components/Texts/ErrorText.vue";

const props = defineProps<{
  dialogId: number;
}>();

const gameStore = useGameStore();

const textValue = ref("");

const onSubmit = () => {
  gameStore.addCustomAction(textValue.value);
  if (!errorAction.value) {
    textValue.value = "";
  }
};

const customActions = computed(() => {
  return gameStore.localPlayersActions;
});

const errorAction = computed(() => {
  return gameStore.errors.find((error) => error.path.includes("actionCustom"));
});
</script>
<template>
  <BottomSheet
    :out-side-closable="true"
    :all-draggable="false"
    :dialog-id="props.dialogId"
  >
    <div class="flex flex-col space-y-4">
      <PrimaryLabel
        text="Ajouter une action pour la partie"
        class="mb-2 text-left"
      />
      <ExtraSmallText
        class="text-custom-lightGray text-left"
        text="Personne ne pourra voir ses actions en dehors de vous.
          Elles seront ajoutées en priorités par rapport aux actions des différentes
          catégories séléctionnées."
      />
      <PrimaryLabel text="Action :" class="text-left" />
      <InputTextarea
        name="action"
        placeholder="Julie fais 3 pompes ou boit 4 gorgées."
        id="action"
        :is_required="true"
        v-model="textValue"
      />
      <ErrorText
        v-if="errorAction"
        :text="errorAction.message"
        class="mt-2 text-left"
      />
      <PrimaryButton @click="onSubmit" label="Ajouter" />
      <hr />
      <SecondaryButton
        @click="gameStore.deleteAllCustomActions"
        label="Tous supprimer"
      />
      <div
        v-for="(customAction, index) in customActions"
        :key="customAction.description"
        class="grid grid-cols-12"
      >
        <ExtraSmallText
          :text="customAction.description"
          class="col-span-10 text-left"
        />
        <i
          class="bi bi-trash3 col-span-2 text-white text-right"
          @click="gameStore.deleteCustomAction(index)"
        ></i>
      </div>
    </div>
  </BottomSheet>
</template>
