<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <p
    class="text-3xl w-full md:text-4xl text-center tracking-wide z-50 break-words text-white"
  >
    {{ props.text }}
  </p>
</template>
