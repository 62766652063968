<script setup lang="ts">
import InputTextWithSubmit from "@/components/Form/Common/InputTextWithSubmit.vue";
import PlayerItem from "@/components/Game/PlayerItem.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import { useGameStore } from "@/Store/useGameStore";
import { computed, ComputedRef } from "vue";
import { defineProps } from "vue/dist/vue";
import ErrorText from "@/components/Texts/ErrorText.vue";
const gameStore = useGameStore();

const props = defineProps<{
  displayErrors?: boolean;
}>();

const players: ComputedRef<string[]> = computed(() => {
  return gameStore.players;
});

const submitFunction = (name: string): void => {
  gameStore.addPlayer(name);
};

const errors = computed(() => {
  return gameStore.errors;
});
</script>
<template>
  <div class="flex flex-col space-y-4">
    <PrimaryLabel text="Joueurs :" class="mb-2 text-left" />
    <InputTextWithSubmit
      id="player-name"
      name="player-name"
      placeholder="Joueur"
      :is_required="true"
      :function="submitFunction"
    />
    <ul v-if="props.displayErrors">
      <li v-for="(error, index) in errors" :key="index">
        <ErrorText :text="error.message" class="mt-2" />
      </li>
    </ul>
    <div class="flex flex-row flex-wrap">
      <PlayerItem
        v-for="player in players"
        :key="player"
        :name="player"
        class="mb-4 mr-4"
      />
    </div>
  </div>
</template>
