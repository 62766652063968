<script setup lang="ts">
import { defineProps } from "vue";
import { useGameStore } from "@/Store/useGameStore";

const gameStore = useGameStore();

const props = defineProps<{
  name: string;
}>();
</script>

<template>
  <div
    @click="gameStore.removePlayer(props.name)"
    class="flex flex-row text-white md:text-lg text-md px-2 py-1 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-700 cursor-pointer"
  >
    <p class="mt-0.5 md:mt-0">
      {{ props.name }}
    </p>
    <p class="md:mt-0.5 text-xl">
      <i class="bi bi-x"></i>
    </p>
  </div>
</template>
