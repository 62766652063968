<script setup lang="ts">
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import { useGameStore } from "@/Store/useGameStore";
import { computed, ComputedRef, defineProps, ref, watch } from "vue";
import ErrorText from "@/components/Texts/ErrorText.vue";
import InputNumber from "@/components/Form/Common/InputNumber.vue";
import { GameStates } from "@/Interfaces/GameStates";

const gameStore = useGameStore();

const props = defineProps<{
  displayErrors?: boolean;
}>();

const minimumDrinks = ref(gameStore.minimumDrinks);
const maximumDrinks = ref(gameStore.maximumDrinks);

const errors: ComputedRef<string[]> = computed(() => {
  return gameStore.errors;
});

const inGame: ComputedRef<GameStates> = computed(() => {
  return gameStore.inGame;
});

watch(
  () => minimumDrinks,
  () => {
    if (inGame.value === GameStates.Started) {
      gameStore.updateMinimumAndMaximumDrinksWithVerification(
        minimumDrinks.value,
        maximumDrinks.value
      );
    } else {
      gameStore.updateMinimumDrinks(minimumDrinks.value);
    }
  },
  { deep: true }
);

watch(
  () => maximumDrinks,
  () => {
    if (inGame.value === GameStates.Started) {
      gameStore.updateMinimumAndMaximumDrinksWithVerification(
        minimumDrinks.value,
        maximumDrinks.value
      );
    } else {
      gameStore.updateMaximumDrinks(maximumDrinks.value);
    }
  },
  { deep: true }
);

const errorMinimumAndMaximumDrinks = computed(() => {
  return gameStore.errors.find((error) =>
    error.path.includes("errorMinimumAndMaximumDrinks")
  );
});
</script>
<template>
  <div class="flex flex-col space-y-4">
    <PrimaryLabel text="Nombre de gorgées" class="mb-2 text-left" />
    <div class="flex flex-row space-x-4 md:flex-col md:space-x-0 md:space-y-4">
      <div class="flex flex-col items-start md:flex-row md:items-center">
        <label for="minimumDrinks" class="mr-4 text-white">Minimum</label>
        <InputNumber
          id="minimumDrinks"
          name="minimumDrinks"
          placeholder="1"
          :is_required="true"
          v-model.number="minimumDrinks"
        />
      </div>
      <div class="flex flex-col items-start md:flex-row md:items-center">
        <label for="maximumDrinks" class="mr-4 text-white">Maximum</label>
        <InputNumber
          id="maximumDrinks"
          name="maximumDrinks"
          placeholder="5"
          :is_required="true"
          v-model.number="maximumDrinks"
        />
      </div>
    </div>
    <ErrorText
      v-if="errorMinimumAndMaximumDrinks"
      :text="errorMinimumAndMaximumDrinks.message"
      class="mt-2 text-left"
    />
    <ul v-if="props.displayErrors">
      <li v-for="(error, index) in errors" :key="index">
        <ErrorText :text="error.message" class="mt-2" />
      </li>
    </ul>
  </div>
</template>
