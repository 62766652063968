<script setup lang="ts">
import { defineProps } from "vue/dist/vue";
import ModalContainer from "@/components/Modals/ModalContainer.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import ModalText from "@/components/Texts/ModalText.vue";
import SecondaryButton from "@/components/Form/Common/SecondaryButton.vue";
import PrimaryButton from "@/components/Form/Common/PrimaryButton.vue";
import { useDialogsStore } from "@/Store/useDialogsStore";
import { useGameStore } from "@/Store/useGameStore";

const props = defineProps<{
  dialogId: number;
}>();

const dialogsStore = useDialogsStore();
const gameStore = useGameStore();
</script>
<template>
  <ModalContainer :dialog-id="props.dialogId" :out-side-closable="true">
    <div class="flex flex-col space-y-4">
      <PrimaryLabel text="Arrêter la partie en cours" class="mb-2" />
      <ModalText>
        Êtes vous sûre de vouloir arrêter la partie en cours ?
      </ModalText>
      <div class="flex flex-row space-x-4">
        <SecondaryButton
          label="Continuer"
          class="w-1/2"
          @click="dialogsStore.closeAllModals()"
        />
        <PrimaryButton
          label="Arrêter"
          class="w-1/2"
          @click="gameStore.stopAndRestartGame()"
        />
      </div>
    </div>
  </ModalContainer>
</template>
