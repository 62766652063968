<script setup lang="ts">
import { defineProps } from "vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import EditNumberDrinks from "@/components/Form/EditNumberDrinks.vue";

const props = defineProps<{
  dialogId: number;
}>();
</script>
<template>
  <BottomSheet
    :out-side-closable="true"
    :all-draggable="false"
    :dialog-id="props.dialogId"
  >
    <EditNumberDrinks :display-errors="true" />
  </BottomSheet>
</template>
