<script setup lang="ts">
import { computed, ref } from "vue";
import BigTitle from "@/components/Title/BigTitle.vue";
import MediumTitle from "@/components/Title/MediumTitle.vue";
import { useRouter } from "vue-router";
import SliderOval from "@/components/Slider/SliderOval.vue";
import SliderRound from "@/components/Slider/SliderRound.vue";
import SmallText from "@/components/Texts/SmallText.vue";
import PrimaryButton from "@/components/Form/Common/PrimaryButton.vue";

const router = useRouter();

const gradientStyle = computed(() => {
  return `linear-gradient(to right, #4338ca, #9333ea}) 1`;
});

const currentSlide = ref(0);
const slides: {
  title: string;
  text: string;
  img: string;
}[] = [
  {
    title: "Drinkolow",
    text: "Bienvenue sur l'application ultime de Drinkolow! Transformez vos soirées avec une expérience de picole personnalisée.",
    img: "slider1.png",
  },
  {
    title: "Personnalisation des Joueurs",
    text: "Adaptez votre expérience de jeu en modifiant les joueurs en cours de partie. Ajoutez et supprimez les joueurs pour une soirée encore plus mémorable.",
    img: "slider2.png",
  },
  {
    title: "Choix de Catégories",
    text: "Explorez une variété infinie de catégories pour pimenter le jeu. Des questions amusantes aux défis créatifs, personnalisez votre picolo selon vos préférences.",
    img: "slider3.png",
  },
  {
    title: "Nombre de Gorgées Personnalisé",
    text: "Décidez ensemble du niveau d'intensité de la fête en définissant le nombre de gorgées par action. De 1 à 15 gorgées par actions, laissez les joueurs guider l'ambiance.",
    img: "slider4.png",
  },
  {
    title: "Gestion des Timers",
    text: "Chaque action compte! Gérez facilement les timers pour les actions spécifiques. Visualisez, recommencez, arrêtez, mettez en pause - le contrôle est entre vos mains.",
    img: "slider5.png",
  },
  {
    title: " Menu d'Aide Intégré",
    text: "Besoin d'un coup de main? Consultez notre menu d'aide intégré pour des instructions rapides et des astuces. Plus de confusion, juste du plaisir!",
    img: "slider6.png",
  },
  {
    title: "Nombre de Tours",
    text: "Contrôlez la durée de votre soirée en ajustant le nombre de tours. Une partie rapide entre amis ou une nuit épique, c'est à vous de décider.",
    img: "slider7.png",
  },
  {
    title: "Commencer Maintenant",
    text: "Commencez dès maintenant à créer des souvenirs inoubliables! C'est le moment de rire, de trinquer et de profiter de l'aventure!",
    img: "slider8.png",
  },
];

const changeSlide = (index: number) => {
  currentSlide.value = index;
};

const redirectToRouteGame = () => {
  router.push("game");
};
</script>

<template>
  <div
    class="border h-screen border-8 box-border"
    :style="{ borderImage: gradientStyle }"
  >
    <div
      class="relative flex justify-center h-full w-full text-white"
      id="bg-custom"
    >
      <div
        class="flex flex-col space-y-8 backdrop-blur-3xl p-8 w-full h-full overflow-x-auto"
      >
        <BigTitle title="Drinkolow" class="text-left" />
        <div class="flex flex-col space-y-4 md:flex-row">
          <img
            :src="'img/slider/' + slides[currentSlide].img"
            :alt="slides[currentSlide].img"
            class="md:w-1/3 mb-4 md:mr-4"
          />
          <div class="flex flex-col space-y-8 md:pt-8">
            <MediumTitle :title="slides[currentSlide].title" />
            <SmallText :text="slides[currentSlide].text" />
            <div
              class="absolute bottom-8 right-8 left-8 flex flex-col space-y-8"
            >
              <div class="flex flex-row space-x-4 mx-auto">
                <div v-for="(slide, index) of slides" :key="index">
                  <SliderOval v-if="index === currentSlide" />
                  <SliderRound v-else @click="changeSlide(index)" />
                </div>
              </div>
              <div
                v-if="currentSlide !== slides.length - 1"
                class="flex flex-row space-x-4"
              >
                <router-link
                  to="/game"
                  class="text-white md:text-lg text-md px-2 py-1 rounded-lg cursor-pointer border w-1/2 text-center"
                >
                  Passer
                </router-link>
                <PrimaryButton
                  label="Prochain"
                  class="w-1/2"
                  @click="changeSlide(currentSlide + 1)"
                />
              </div>
              <div v-else class="flex flex-row space-x-4">
                <router-link
                  to="/game"
                  class="w-full text-white md:text-lg text-md px-2 py-1 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-700 cursor-pointer text-center"
                >
                  Jouer
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#bg-custom {
  position: relative;
  background-color: black;
}

#bg-custom::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(147, 51, 234);
  background: radial-gradient(circle, #9333ea 0%, #000 100%);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  opacity: 0.5;
}

@keyframes gradient {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
