<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  title: string;
}>();
</script>

<template>
  <h1 class="font-semibold text-white md:text-4xl text-3xl">
    {{ props.title }}
  </h1>
</template>
