<script setup lang="ts">
import { computed, ComputedRef, Ref } from "vue";
import Timer from "@/Interfaces/Timer";
import BigTitle from "@/components/Title/BigTitle.vue";
import MediumText from "@/components/Texts/MediumText.vue";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";

const gameStore = useGameStore();

const indexAction: ComputedRef<number> = computed(() => {
  return gameStore.indexAction;
});

const gameActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.gameActions;
});

const timer: Ref<Timer | undefined> = computed(() => {
  return gameStore.timers.find(
    (timer) => timer.action_id === indexAction.value
  );
});
</script>

<template>
  <div class="w-full h-full flex flex-col space-y-8">
    <BigTitle
      :title="gameActions[indexAction].category.name"
      class="text-center"
    />
    <MediumText :text="gameActions[indexAction].description" />
    <p
      v-if="timer"
      class="text-3xl md:text-4xl bg-white/10 p-4 text-center rounded-lg"
    >
      {{ gameStore.secondsToMinutesAndSeconds(timer.time_left) }}
    </p>
  </div>
</template>
