<script setup lang="ts">
import { defineProps } from "vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import EditCategories from "@/components/Form/EditCategories.vue";

const props = defineProps<{
  dialogId: number;
}>();
</script>
<template>
  <BottomSheet
    :out-side-closable="true"
    :all-draggable="false"
    :dialog-id="props.dialogId"
  >
    <EditCategories />
  </BottomSheet>
</template>
