<script setup lang="ts">
import { computed, defineProps } from "vue";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import EditPlayers from "@/components/Form/EditPlayers.vue";

const props = defineProps<{
  dialogId: number;
}>();
</script>
<template>
  <BottomSheet
    :out-side-closable="true"
    :all-draggable="false"
    :dialog-id="props.dialogId"
  >
    <EditPlayers :display-errors="true" />
  </BottomSheet>
</template>
