<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <p class="text-red-600 font-semibold md:text-lg text-md">
    <i class="bi bi-exclamation-triangle"></i>
    {{ props.text }}
  </p>
</template>

<style scoped lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
</style>
