<script setup lang="ts">
import { computed, ComputedRef, Ref, ref, watch } from "vue";
import Timer from "@/Interfaces/Timer";
import { GameStates } from "@/Interfaces/GameStates";
import GameMenu from "@/components/Game/GameMenu.vue";
import GameInterface from "@/components/Game/GameInterface.vue";
import GameFinished from "@/components/Game/GameFinished.vue";
import TimerMenu from "@/components/Menu/TimerMenu.vue";
import MenuWithSmallMenus from "@/components/Menu/MenuWithSmallMenus.vue";
import ActionHelperModal from "@/components/Menu/ActionHelperModal.vue";
import ModalContainer from "@/components/Modals/ModalContainer.vue";
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import ModalText from "@/components/Texts/ModalText.vue";
import TimerModalElements from "@/components/Timers/TimerModalElements.vue";
import { useDialogsStore } from "@/Store/useDialogsStore";
import BottomSheet from "@/components/Modals/BottomSheet.vue";
import { useGameStore } from "@/Store/useGameStore";
import Category from "@/Interfaces/Category";
import { Action } from "@/Interfaces/Action";
import PacManLoading from "@/components/Menu/PacManLoading.vue";
import BigTitle from "@/components/Title/BigTitle.vue";
import TestForm from "@/components/Form/Common/TestForm.vue";
const gameStore = useGameStore();

// Get all necessary reactive stuff from gameStore
const allCategories: ComputedRef<Category[]> = computed(() => {
  return gameStore.allCategories;
});

const allActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.allActions;
});

const indexAction: ComputedRef<number> = computed(() => {
  return gameStore.indexAction;
});

const inGame: ComputedRef<number> = computed(() => {
  return gameStore.inGame;
});

const timers: ComputedRef<Timer[]> = computed(() => {
  return gameStore.timers;
});

const gameActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.gameActions;
});

// Get data from backend
gameStore.getAllCategoriesAndActions();
/*
setTimeout(() => {
  gameStore.getAllCategoriesAndActions();
}, 50000);*/

// Manage dialogs
const dialogsStore = useDialogsStore();
const timersDialog = dialogsStore.createNewDialog();
const modalTimerFinished = dialogsStore.createNewDialog();
const timerFinishedSound = new Audio("/sounds/notifications.mp3");

// Manage border color
const gradientStyle = computed(() => {
  const color = ref("#4338ca");
  const color2 = ref("#9333ea");
  if (gameActions.value.length > 0 && inGame.value === GameStates.Started) {
    color.value = gameActions.value[indexAction.value].category.color;
    color2.value = gameActions.value[indexAction.value].category.color2;
  }
  return `linear-gradient(to right, ${color2.value}, ${color.value}) 1`;
});

// Manage timer
const timerExist: Ref<boolean> = computed(() => {
  return (
    gameStore.timers.find(
      (timer: Timer) => timer.action_id === indexAction.value
    ) !== undefined
  );
});

watch(
  () => gameStore.timerAlert,
  () => {
    dialogsStore.openModal(modalTimerFinished);
    timerFinishedSound.play();
  },
  { deep: true }
);
</script>

<template>
  <!-- loader -->
  <div
    v-if="allActions.length === 0 || allCategories.length === 0"
    class="border h-screen border-8 box-border"
    :style="{ borderImage: gradientStyle }"
  >
    <div
      class="relative flex flex-col justify-center h-full w-full text-white bg-custom-black p-4"
    >
      <BigTitle
        title="Chargement des données ..."
        class="text-white text-left mx-4"
      />
      <PacManLoading class="w-full" :style="{ 'margin-left': '60px' }" />
    </div>
  </div>
  <!-- Game -->
  <div v-else>
    <div
      class="border h-screen border-8 box-border"
      :style="{ borderImage: gradientStyle }"
    >
      <div
        class="relative flex justify-center h-full w-full text-white"
        id="bg-custom"
      >
        <div
          v-if="inGame === GameStates.Started"
          class="absolute w-1/2 h-full left-0 z-10 bg-transparent"
          @click="gameStore.lastAction()"
        ></div>
        <div
          v-if="inGame === GameStates.Started"
          class="absolute w-1/2 h-full right-0 z-10 bg-transparent"
          @click="gameStore.nextAction()"
        ></div>
        <div
          v-if="inGame === GameStates.Finished"
          class="absolute w-full h-full right-0 z-10 bg-transparent"
          @click="gameStore.restartGame()"
        ></div>
        <div
          class="flex flex-col space-y-4 backdrop-blur-3xl p-8 w-full h-full flex items-center overflow-x-auto"
        >
          <GameMenu
            v-if="inGame === GameStates.NotStarted"
            class="flex flex-col space-y-4"
          />
          <GameInterface v-else-if="inGame === GameStates.Started" />
          <GameFinished v-else-if="inGame === GameStates.Finished" />
        </div>
      </div>
    </div>
    <div
      v-if="inGame === GameStates.Started"
      class="z-50 fixed bottom-4 right-4 left-4 h-[10vh] grid grid-cols-4 content-end md:w-1/4 md:left-auto md:right-1 md:bottom-5"
    >
      <TimerMenu
        v-if="inGame === GameStates.Started && timerExist"
        class="col-span-2"
      />
      <div v-else class="col-span-2" />
      <div
        v-if="inGame === GameStates.Started"
        class="mx-auto col-span-1 w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
        @click="dialogsStore.openModal(timersDialog)"
      >
        <span class="text-white text-3xl z-0"
          ><i class="bi bi-stopwatch"></i
        ></span>
      </div>
      <div v-else class="col-span-1" />
      <MenuWithSmallMenus class="col-span-1" />
    </div>
    <div v-else class="fixed -bottom-3 right-8 h-[10vh]">
      <MenuWithSmallMenus class="col-span-1" />
    </div>
    <ActionHelperModal
      v-if="
        inGame === GameStates.Started &&
        indexAction >= 1 &&
        gameActions[indexAction].help
      "
    />

    <!-- Modal Timers informations -->
    <BottomSheet
      :dialog-id="timersDialog"
      :out-side-closable="true"
      :all-draggable="false"
    >
      <div class="flex flex-col space-y-4 h-full overflow-y-auto">
        <PrimaryLabel text="Timers :" class="mb-2" />
        <ModalText v-if="timers.length > 0">
          Appuyez sur le timer de votre choix afin de voir l'action liée.
        </ModalText>
        <ModalText v-else> Pas de timer en cours. </ModalText>
        <div class="flex flex-col-reverse w-full">
          <div v-for="(timer, index) in timers" :key="index">
            <TimerModalElements class="mb-4" :timer="timer" :index="index" />
          </div>
        </div>
      </div>
    </BottomSheet>
    <!-- Modal timer finished -->
    <ModalContainer :dialog-id="modalTimerFinished" :out-side-closable="true">
      <div class="flex flex-col space-y-4">
        <PrimaryLabel text="Temps écoulé !" class="mb-2" />
        <ModalText>
          {{ gameActions[gameStore.timerAlert].description }}
        </ModalText>
      </div>
    </ModalContainer>
  </div>
</template>

<style scoped>
#bg-custom {
  position: relative;
  background-color: black;
}

#bg-custom::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(147, 51, 234);
  background: radial-gradient(circle, #9333ea 0%, #000 100%);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  opacity: 0.5;
}

@keyframes gradient {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
