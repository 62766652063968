<script setup lang="ts">
import { defineEmits, defineProps } from "vue/dist/vue";
import SelectOption from "@/Interfaces/SelectOption";

const props = defineProps<{
  modelValue: number | null;
  options: SelectOption[];
}>();

const emit = defineEmits(["update:modelValue"]);

const updateValue = ($event: Event) => {
  emit("update:modelValue", ($event.target as HTMLSelectElement).value);
};
</script>

<template>
  <select
    :value="props.modelValue"
    class="w-full text-white placeholder-gray-400 focus:backdrop-blur-sm focus:border-purple-700 bg-opacity-10 bg-transparent border border-white rounded-lg px-4 py-2 text-[#e2e1e5] transition duration-200 shadow-md"
    @input="updateValue($event)"
  >
    <option
      v-for="option in props.options"
      :value="option.value"
      :key="option.value"
      class="bg-custom-black"
    >
      {{ option.label }}
    </option>
  </select>
</template>
