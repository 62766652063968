<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useDialogsStore } from "@/Store/useDialogsStore";

const props = defineProps<{
  dialogId: number;
  outSideClosable?: boolean;
}>();

const dialogsStore = useDialogsStore();

const isOpen = computed<boolean>(() => {
  return dialogsStore.dialogs[props.dialogId];
});

function close() {
  dialogsStore.closeAllModals();
}
</script>

<template>
  <div v-if="isOpen" class="relative z-10">
    <!-- permet la fermeture depuis la zone hors de la modal -->
    <div
      v-if="props.outSideClosable"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      @click="close"
    ></div>
    <div
      v-else
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>
    <!-- modal -->
    <div class="fixed inset-0 z-10 overflow-y-auto" @click="close">
      <div
        class="flex min-h-full items-end justify-center items-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="relative transform overflow-hidden text-left shadow-xl transition-all bg-gradient-to-b from-gray-900 to-gray-800 rounded-lg"
          @click.stop
        >
          <div class="flex items-start p-8 flex-col overflow-x-auto w-full">
            <button
              type="button"
              class="text-gray-400 self-end bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              @click="dialogsStore.closeAllModals"
            >
              <i class="bi bi-x-lg"></i>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="w-full">
              <div class="text-4xl mr-4"><slot></slot></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
