<script setup lang="ts">
/* Inspiré de : https://play.vuejs.org/#eNp9UstOwzAQ/BXjCyDRBKniUtKKh3qAAyDo0ZfgblO3jm3Zm7RV1X9nHUelSKineGdnxrMb7/mjc1nbAB/xIkivHLIA2LiJMKp21iPbMw8LdmALb2t2SdRLYYSR1gSiLu2GjSPhCn0D18IUeXIhPRUItdMlAlWMFd8NojXsQWol12PBe/VF/Ao+mdmq0lDkiZYkM1+aoFD1NSGOtQO16NWkWoLWtshjYOrmfwRFfhKAyoA7HY9ZOwCD4AelRNXCTYdoKFvoEbaPbnj0GjHrSqlwx26zu8CgDHAvzCGaHr3ifk6d0CaXXjlit0lCG+pi8BuOgda4UFW2CtbQH+j4gktbO6XBv7t4dxB8lJxir6RpN68dFjdOFyZcLkGu/8FXYRsxwT88BPAtCH7sYekrwNSefr3Bls7HZm3njSb2meYnBKubmDHRnhozp9gnvC7tS/eOlKlmYbpFoJX2Q8WgkXno+ILT23o+M/pv3GE27HS0T374AbBs70k= */
import { computed, ComputedRef, Ref } from "vue";
import Timer from "@/Interfaces/Timer";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";

const gameStore = useGameStore();

const indexAction: ComputedRef<number> = computed(() => {
  return gameStore.indexAction;
});

const gameActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.gameActions;
});

const timer: Ref<Timer | undefined> = computed(() => {
  return gameStore.timers.find(
    (timer) => timer.action_id === gameStore.indexAction
  );
});
</script>

<template>
  <div v-if="timer" class="grid grid-cols-2">
    <!-- Cercle principal fixé -->
    <div
      v-if="!timer.started && timer.time_left > 0"
      class="mx-auto w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
      @click="gameStore.startTimer(gameActions[indexAction]?.timer_id ?? 0)"
    >
      <span class="text-white text-3xl"
        ><i class="bi bi-play text-green-500"></i
      ></span>
    </div>
    <div
      v-if="timer.started"
      class="mx-auto w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
      @click="gameStore.pauseTimer(gameActions[indexAction]?.timer_id ?? 0)"
    >
      <span class="text-white text-3xl"
        ><i class="bi bi-pause text-yellow-500"></i
      ></span>
    </div>
    <div
      v-if="
        timer.started ||
        timer.time_full === 0 ||
        (!timer.started && timer.time_left !== timer.time_full)
      "
      class="mx-auto w-16 h-16 rounded-full flex justify-center items-center cursor-pointer transition-transform duration-1000 ease-in-out transform bg-gradient-to-b from-gray-900 to-gray-800"
      @click="gameStore.restartTimer(gameActions[indexAction]?.timer_id ?? 0)"
    >
      <span class="text-white text-3xl"
        ><i class="bi bi-arrow-repeat text-blue-500"></i
      ></span>
    </div>
  </div>
</template>
