<script setup lang="ts">
/* Inspiré de : https://play.vuejs.org/#eNp9UstOwzAQ/BXjCyDRBKniUtKKh3qAAyDo0ZfgblO3jm3Zm7RV1X9nHUelSKineGdnxrMb7/mjc1nbAB/xIkivHLIA2LiJMKp21iPbMw8LdmALb2t2SdRLYYSR1gSiLu2GjSPhCn0D18IUeXIhPRUItdMlAlWMFd8NojXsQWol12PBe/VF/Ao+mdmq0lDkiZYkM1+aoFD1NSGOtQO16NWkWoLWtshjYOrmfwRFfhKAyoA7HY9ZOwCD4AelRNXCTYdoKFvoEbaPbnj0GjHrSqlwx26zu8CgDHAvzCGaHr3ifk6d0CaXXjlit0lCG+pi8BuOgda4UFW2CtbQH+j4gktbO6XBv7t4dxB8lJxir6RpN68dFjdOFyZcLkGu/8FXYRsxwT88BPAtCH7sYekrwNSefr3Bls7HZm3njSb2meYnBKubmDHRnhozp9gnvC7tS/eOlKlmYbpFoJX2Q8WgkXno+ILT23o+M/pv3GE27HS0T374AbBs70k= */

import { computed, ComputedRef, defineProps } from "vue";
import Timer from "@/Interfaces/Timer";
import ModalText from "@/components/Texts/ModalText.vue";
import { useGameStore } from "@/Store/useGameStore";
import { Action } from "@/Interfaces/Action";
const gameStore = useGameStore();

const props = defineProps<{
  timer: Timer;
  index: number;
}>();

const gameActions: ComputedRef<Action[]> = computed(() => {
  return gameStore.gameActions;
});
</script>

<template>
  <div class="rounded-lg border border-white px-4 py-2 flex flex-col space-y-4">
    <div class="flex flex-row justify-center items-center space-x-4">
      <ModalText>{{
        gameStore.secondsToMinutesAndSeconds(props.timer.time_left)
      }}</ModalText>
      <i
        v-if="!props.timer.started && props.timer.time_left > 0"
        @click="gameStore.startTimer(props.index)"
        class="bi bi-play text-green-500"
      ></i>
      <i
        v-if="props.timer.started"
        @click="gameStore.pauseTimer(props.index)"
        class="bi bi-pause text-yellow-500"
      ></i>
      <i
        v-if="
          props.timer.started ||
          props.timer.time_left === 0 ||
          (!props.timer.started &&
            props.timer.time_left !== props.timer.time_full)
        "
        @click="gameStore.restartTimer(props.index)"
        class="bi bi-arrow-repeat text-blue-500"
      ></i>
      <i
        @click="gameStore.stopTimer(props.index)"
        class="bi bi-x text-red-500"
      ></i>
    </div>
    <div>
      <ModalText>
        <hr class="mb-2" />
        {{ gameActions[props.timer.action_id].description }}
      </ModalText>
    </div>
  </div>
</template>
