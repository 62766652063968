<script setup lang="ts">
import { defineProps, ref } from "vue";
import InputText from "@/components/Form/Common/InputText.vue";

const props = defineProps<{
  name: string;
  placeholder: string;
  id: string;
  is_required: boolean;
  function: (value: string) => void;
}>();

const textValue = ref("");

const onSubmit = () => {
  props.function(textValue.value);
  textValue.value = "";
};
</script>

<template>
  <div class="flex flex-row space-x-1">
    <InputText
      :name="props.name"
      :placeholder="props.placeholder"
      :id="props.id"
      :is_required="props.is_required"
      v-model="textValue"
    />
    <button class="h-10" type="button" name="submit" @click="onSubmit">
      <span class="text-white text-4xl"><i class="bi bi-plus"></i></span>
    </button>
  </div>
</template>
