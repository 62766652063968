<script setup lang="ts">
import PrimaryLabel from "@/components/Form/Common/PrimaryLabel.vue";
import CheckBoxWithLabel from "@/components/Form/Common/CheckBoxWithLabel.vue";
import ErrorText from "@/components/Texts/ErrorText.vue";
import { computed, ComputedRef } from "vue";
import { useGameStore } from "@/Store/useGameStore";
import Category from "@/Interfaces/Category";

const gameStore = useGameStore();

const selectedCategories: ComputedRef<number[]> = computed(() => {
  return gameStore.selectedCategories;
});

const allCategories: ComputedRef<Category[]> = computed(() => {
  return gameStore.allCategories;
});

const errorCategories = computed(() => {
  return gameStore.errors.find((error) => error.path.includes("categories"));
});
</script>
<template>
  <div class="flex flex-col space-y-4">
    <PrimaryLabel
      :text="`Catégories sélectionnées : ${selectedCategories.length}`"
      class="mb-2 text-left"
    />
    <div class="flex flex-row flex-wrap">
      <div v-for="category in allCategories" :key="category.id">
        <CheckBoxWithLabel
          :value="category.id"
          :name="category.name"
          :is_required="true"
          v-model="selectedCategories"
          :color="category.color"
          :color2="category.color2"
          class="mb-4 mr-4"
        />
      </div>
    </div>
    <ErrorText
      v-if="errorCategories"
      :text="errorCategories.message"
      class="mt-2 text-left"
    />
  </div>
</template>
